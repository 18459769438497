<script setup>
import {onMounted, ref} from 'vue'
import { useEventListener } from "@vueuse/core"
import Plyr from 'plyr';

const getRandom = (min, max) => Math.random() * (max - min) + min;
const iframeHeight = ref(0)
const hasPlayed = ref(false)
const windowSizeHandler = () => {
  iframeHeight.value = document.getElementById(props.video.name).scrollWidth / 16 * 9
}
const props = defineProps(['video'])
const cheer = ref(0)
const changeCheer = () => {
  if(cheer.value != 1){
    cheer.value = 1
    let timer = setInterval(() => {
      clearInterval(timer);
      cheer.value = 0
    }, 3000);
  }
}

var Mp4ToBlob = {
  mediaSource:new MediaSource(),
  // 检查是否支持 MediaSource 或者 mimeCodec
  checkSupported: function (cb) {
    if ('MediaSource' in window && MediaSource.isTypeSupported(this.mimeCodec)) {
      return true
    } else {
      this.video.src  = this.assetUrl; // 如果不支持，则直接将 src 修改成原始的url，保证兼容性
      console.error('Unsupported MediaSource or unsupported MIME type or codec: ', this.mimeCodec);
      return false
    }
  },
  fetchUrl: function(url) {
    const self = this;
    return new Promise((resolve, reject) => {
      const mediaSource = this.mediaSource = new MediaSource();
      mediaSource.addEventListener('sourceopen', function () {
        URL.revokeObjectURL(self.video.src);
        const sourceBuffer = mediaSource.addSourceBuffer(self.mimeCodec); // 指定适当的 MIME 类型

        let bufferQueue = []; // 存储待加载的视频数据
        let loading = false; // 标志变量，表示是否正在加载数据
        let readerDone = false; // 标志变量，表示是否加载完数据
        function loadNextBuffer() {
          if (bufferQueue.length > 0 && !loading) {
            loading = true;
            let videoData = bufferQueue.shift();
            sourceBuffer.appendBuffer(videoData);
          } else if (readerDone && !loading) {
            console.log("readerDone");
            mediaSource.endOfStream();
          }
        }
        // 当获取到视频数据时，将数据推入缓冲队列
        const fetch = window.fetch;
        const resource = new Request(url);
        const options = {};
        options['method'] = "GET";
        options['headers'] = {"Access-Control-Allow-Headers": "*", "Access-Control-Allow-Origin": "*"};
        fetch(resource, options).then((response) => {
          if (!response.ok) {
            return reject(new Error(`Failed to fetch ${url}`));
          }
          sourceBuffer.addEventListener('updateend', function () {
            loading = false;
            loadNextBuffer(); // 当前数据加载完成后，尝试加载下一段数据
          });

          const stream = response.body;
          const reader = stream.getReader();

          // read() returns a promise that resolves
          // when a value has been received
          reader.read().then(function process({done, value}) {
            // Result objects contain two properties:
            // done  - true if the stream has already given you all its data.
            // value - some data. Always undefined when done is true.
            if (done) {
              console.log("Stream complete");
              readerDone = done;
              loadNextBuffer();
              return;
            }
            // value for fetch streams is a Uint8Array
            // chunk = chunk ? self.#decodeBuffer(chunk) : void 0;
            // 在加载视频数据时，将数据推入缓冲队列
            const chunk = value;
            bufferQueue.push(chunk);
            loadNextBuffer(); // 尝试加载数据
            // console.log(chunk);

            // Read some more, and call this function again
            return reader.read().then(process);
          });
        }).catch(reject);
      });
      resolve(mediaSource);
    });
  },
  // 初始化 selector / assetUrl / mimeCodec
  // selector：video的选择器 exp: '#video'
  // assetUrl: video的请求地址 exp : './v.mp4'
  // mimeCodec: 编码模式  exp:  'video/mp4; codecs="avc1.640028, mp4a.40.2"'
  init: function (selector, assetUrl, mimeCodec) {
    this.video = document.querySelector(selector); // 获取video dom
    this.assetUrl = assetUrl;
    this.mimeCodec = mimeCodec;
    if(this.checkSupported()){
      this.start();// 开启
    }
  },
  start: function () {
    this.fetchUrl(this.assetUrl).then(mediaSource => {
      this.video.src = URL.createObjectURL(mediaSource);
    });
  },
};

onMounted(() => {
  const player = new Plyr(`#${props.video.name}`,{
    controls: [
      'play-large', // The large play button in the center
      'play', // Play/pause playback
      'progress', // The progress bar and scrubber for playback and buffering
      'current-time', // The current time of playback
      'duration', // The full duration of the media
      'fullscreen', // Toggle fullscreen
    ]
  });
  windowSizeHandler()
  useEventListener("resize", windowSizeHandler, false)
  // var url = 'https://recstore.ustc.edu.cn/file/20241104_36c515011c3ade0b7238f83a8008cf2a?Signature=TfXZ5gVjc4P+6dmP9q7V4tcixfY=&Expires=1730772321&AccessKeyId=MAKIG23JM2UB98N0KTQH&response-content-type=video%2Fmp4&response-content-disposition=inline%3Bfilename%3D%22%25E6%259D%25AD%25E5%25B7%259E%25E5%25B0%258F%25E7%2589%2587%25E6%25AE%25B5.mp4%22&storage=moss&filename=%E6%9D%AD%E5%B7%9E%E5%B0%8F%E7%89%87%E6%AE%B5.mp4'; // 视频url
  var url = props.video.src
  var mimeCodec = 'video/mp4; codecs="avc1.4D042A, mp4a.40.2"'; // 根据视频信息原样填写
  player.on('play',()=>{
    if(!hasPlayed.value){
      Mp4ToBlob.init(`#${props.video.name}`, url, mimeCodec); // #video是选择器id
      hasPlayed.value = true
    }
  })
  // var xhr = new XMLHttpRequest();
  // xhr.open('GET', props.video.src, true);
  // xhr.onreadystatechange = function() {
  //   const size = xhr.getResponseHeader('Content-Length');
  //   console.log('size',size)
  // };
  // xhr.send()
})
</script>

<template>
  <div class="framedPicture shadow-xl" v-motion
    :initial="{scale: 0.7, rotate: 0}"
    :enter="{
      scale: 1,
      rotate: getRandom(-5, 5),
      transition: {
        type: 'spring',
        stiffness: 400,
        damping: 20,
        mass: 1
      }
    }"
    :hovered="{scale: 1.05}"
    :tapped="{scale: 1}">
<!--    <img alt="video about ..." class="framedPictureImage" src="https://s2.loli.net/2024/07/04/ZIHQAX2SYVtq4Lp.jpg" v-motion-->
<!--      :enter="{-->
<!--        scale: 1,-->
<!--        transition: {-->
<!--          type: 'spring',-->
<!--          stiffness: 200,-->
<!--          damping: 20,-->
<!--          mass: 1-->
<!--        }-->
<!--      }"-->
<!--      :hovered="{scale: 1.05}"-->
<!--      :tapped="{scale: 1}">-->
<!--    </img>-->
    <div alt="video about ..." class="framedPictureImage" v-motion
         :enter="{
            scale: 1,
            transition: {
              type: 'spring',
              stiffness: 200,
              damping: 20,
              mass: 1
            }
          }"
         :hovered="{scale: 1.05}"
         :tapped="{scale: 1}">
      <video :id="props.video.name" playsinline :data-poster="props.video.img" width="100%" :style="{height: iframeHeight + 'px'}">
<!--        <source :src="props.video.src" type="video/mp4" />-->
      </video>
    </div>
    <div style="position: relative" v-show="cheer">
      <img style="height: 60px;margin-left: 10px;position: absolute;bottom: 0px" src="/xiaoliuya03.gif"/>
    </div>
    <div class="desc">
      <button class="like" @click="changeCheer">🎉 连击点赞</button>
      <div>
        <h1 class="framedPictureNameTag">{{ props.video.name }}</h1>
        <p class="framedPictureTimeTag">{{ props.video.time }}</p>
      </div>
    </div>

  </div>
</template>

<style scoped lang="scss">
.framedPicture {
  width: calc((100% - 3rem) / 2);
  background-color: white;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;

  .framedPictureImage {
    width: 100%;
    margin-bottom: 1.25rem;
  }

  .desc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      height: 50%;
      cursor: pointer;
      background-color: #404663;
      color: white;
      font-size: 1rem;
      font-weight: 400;
      //padding: 0.5em 1.25em;
      padding: 0.5em 0.75em;
      box-shadow:
          0px 1.7px 2.2px rgba(0,0,0,0.02),
          0px 4px 5.3px rgba(0,0,0,0.028),
          0px 7.5px 10px rgba(0,0,0,0.035),
          0px 13.4px 17.9px rgba(0,0,0,0.042),
          0px 25.1px 33.4px rgba(0,0,0,0.05),
          0px 60px 80px rgba(0,0,0,0.07);
      border-radius: 10px;
    }

    .framedPictureNameTag {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: 600;
      color:rgb(60, 60, 60);
    }

    .framedPictureTimeTag {
      font-size: 1rem;
      line-height: 1.5rem;
      font-weight: 300;
      color:rgb(58, 58, 58);
    }
  }
}

// like 特效 begin
.like{
  position: relative;

  &::after{
    font-family: 'zcoolkl';
    position: absolute;
    bottom: 100%;
    //新增 start
    left: 80%;
    //新增 end
    width: max-content;
    font-size: 1.8rem;
    font-style: italic;
    font-weight: bolder;
    background-image: linear-gradient(#FFCF02, #FF7352);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    counter-reset: time var(--t);
    content:'x' counter(time)  counter(time, 真棒);
    animation:count 100s steps(999) forwards;
    animation-play-state: paused;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: .3s .3s, 0s .6s transform;
    z-index: 2147483647;
  }

  &:active::after{
    animation-play-state: running;
    visibility: visible;
    opacity: 1;
    transition: .3s;
    transform: translateY(0);
  }

  &:active {
    animation: shake 0.24s linear infinite;
  }
}

@property --t {
  syntax: '<integer>';
  inherits: false;
  initial-value: 0;
}

@counter-style 白头偕老 {
  system: cyclic;
  symbols: '白头偕老！！！';
  /* range: 51 infinite; */
}
@counter-style 永远幸福 {
  system: cyclic;
  symbols: '永远幸福！！！';
  /* range: 51 infinite; */
  range: 0 98;
  fallback: 白头偕老
}
@counter-style 太甜啦 {
  system: cyclic;
  symbols: '太甜啦！！';
  range: 0 50;
  fallback: 永远幸福
}
@counter-style 真棒 {
  system: cyclic;
  symbols: '真棒！';
  range: 0 20;
  fallback: 太甜啦
}

@keyframes count {
  to {
    --t: 999;
  }
}

@keyframes shake{
  0%{
    transform: translate(0);
  }
  20%{
    transform: translate(-3px, 3px);
  }
  40%{
    transform: translate(-2px, -3px);
  }
  60%{
    transform: translate(3px, 2px);
  }
  80%{
    transform: translate(2px, -3px);
  }
  100%{
    transform: translate(0);
  }
}
//like 特效 end

@media (max-width: 1280px) {
  .framedPicture {
    width: 100%
  }
  .framedPictureImage {
    max-height: 400px !important;
  }
}
</style>