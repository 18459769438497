import {createRouter,createWebHistory} from 'vue-router';
import StarIndex from "@/pages/StarIndex";
import LoveTime from '@/pages/LoveTime.vue';
import LoveTime520 from "@/pages/LoveTime520.vue";
const Home = { template: '<div>Home</div>' }

const routes = [
    {path: '/',component: StarIndex},
    {path: '/loveTime', component: LoveTime520}
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router;



