<script>
export default {
  name: "loading",
  methods: {
    enter(next) {
      let conainer = document.getElementById("loading");
      conainer.classList.remove("loading_out");
      setTimeout(() => {
        next();
        this.$parent.check_loading();
      }, 1000);
    },
    leave() {
      setTimeout (()=> {
        let conainer = document.getElementById("loading");
        conainer.classList.add("loading_out");
      }, 2000)
    },
  },
}
</script>

<template>
  <div id="loading">
    <svg viewBox='0 0 50 50'>
      <circle r='25' cx='25' cy='25'></circle>
    </svg>
    <p>LOADING</p>
    <img src="/xiaoliuya.gif">
  </div>
</template>

<style scoped lang="scss">
#loading {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #f7f7f7;
  z-index: 100000000;
  transition: 1s ease;

  svg {
    width: 5 * 2vmin;
    margin-bottom: 2*2vmin;
    overflow: visible;
    transition: 0.3s ease;

    circle {
      fill: none;
      stroke: #171717;
      stroke-width: 12;
      stroke-dasharray: 160;
      stroke-dashoffset: 160;
      transform-origin: center;
      animation: circle_rotate 3s ease-in infinite;
    }
  }

  p {
    font-family: sans-serif;
    font-size: 2 * 2vmin;
    color: #171717;
    font-weight: 900;
    transition: 0.3s ease;
  }
}

.loading_out {
  transform: translateY(100%);

  svg {
    opacity: 0;
  }

  p {
    opacity: 0;
  }
}

@keyframes circle_rotate {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: 160;
  }

  100% {
    transform: rotate(360deg);
    stroke-dashoffset: -160;
  }
}
</style>