<template>
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
  <Loading ref="loading" />
  <router-view></router-view>
</template>

<script>
import StarIndex from "@/pages/StarIndex";
import Loading from "@/components/Loading";

export default {
  name: 'App',
  components: {
    Loading,
    StarIndex
  },
  methods: {
    check_loading() {
      let timer = setInterval(() => {
        if (document.readyState === "complete") {
          clearInterval(timer);
          this.$refs.loading.leave()
        }
      }, 300);
    }
  },
  mounted() {
    this.check_loading()
    this.$router.beforeEach((to, from, next) => {
      this.$refs.loading.enter(next)
    })
  }
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
