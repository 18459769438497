<style lang="scss" scoped>
.slider-item {
  position: relative;
  overflow: auto;
}

.slider-item--flex-row-center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.slider-item--flex-column-center {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <section :class="['slider-item',`slider-item--${layout}`]" :style="style">
    <slot />
  </section>
</template>

<script>
export default {
  name: 'SliderItem',
  props: {
    layout: {
      type: String,
      default: 'center'
    },
    backgroundColor: {
      type: String,
      default: undefined
    },
    backgroundImage: {
      type: String,
      default: undefined
    },
    height: {
      type: String,
      default: '100vh'
    }
  },
  computed: {
    style () {
      const styles = {}
      this.backgroundColor && (styles['background-color'] = this.backgroundColor)
      this.height && (styles['min-height'] = this.height)
      if (this.backgroundImage) {
        //styles['background-image'] = `url(${require('../assets/image/'+ this.backgroundImage)})`
        styles['background-image'] = 'url('+require('../assets/image/'+ this.backgroundImage)+')'
        styles['background-size'] = 'cover'
        styles['background-position'] = 'center center'
      }
      return styles
    }
  }
}
</script>
