import { createApp } from 'vue'
import router from "@/router";
import App from './App.vue'
import './assets/scss/global.scss'
import './assets/scss/animate.scss'
import './assets/scss/override.scss'
import './assets/scss/font.scss'
import './assets/iconfont/iconfont.css'
import './assets/scss/vue2-animate/vue2-animate.scss'
import './assets/scss/tailwind.scss'
import 'plyr/dist/plyr.css'
import animate from 'animate.css'
import { MotionPlugin } from '@vueuse/motion'

const app=createApp(App)
app.use(router)
app.use(animate)
app.use(MotionPlugin)
app.mount('#app')
