<style lang="scss" scoped>
.index {
  $slide-padding: 48px;

  &__about {
    //margin: $slide-padding;
    max-width: 840px;
    letter-spacing: 1px;
    line-height: 1.6;

    &__button {
      display: flex;
      flex-flow: row nowrap;
      border-top: 1px solid #efefef;
      //padding-top: 16px;
      position: relative;
      justify-content: center;

      &__item {
        &:not(:first-child) {
          margin-left: 16px;
        }
      }

      &__video {
        position: absolute;
        font-size: 10px;
        top:10px
      }
    }
  }

  &__module {
    margin: $slide-padding;

    &__title {
      margin: 36px 0;
      line-height: 1.6;
    }

    &__area {

    }
  }

}

.avatar {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  box-shadow: inset 0 0 10px #000;
  padding: 5px;
  opacity: 1;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -o-transition: all ease 1s;

  &:hover {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
  }
}

.text-white {
  letter-spacing: 1px;
  line-height: 1.6;
  color: #ffffff;
}
</style>

<template>
  <div class="index">
    <StarSky/>
    <Slider>
      <SliderItem layout="flex-column-center">
        <div class="index__about">
          <CustomFont color="#fff" size="3.4rem">
            晶晶，
          </CustomFont>
          <CustomFont color="#fff" size="3.5rem">
            我爱你
          </CustomFont>
          <CustomFont color="#fff" size="1.3rem" height="2">
            (づ￣ 3￣)づ
          </CustomFont>
          <Blocker height="16px" />
          <div class="index__about__button" :style="{paddingTop: visible ? '0px' : '16px'}">
            <div class="index__about__button__video" v-show="visible">
              <Btn style="padding: 2px 3px" @click="$router.push('loveTime')">
                <Icon name="click" color="#fff"/>
                <CustomFont display="inline" color="#fff">
                  甜蜜时刻♥
                </CustomFont>
              </Btn>
            </div>
            <div class="index__about__button__item" v-show="!visible">
              <Btn @click="showLove">
                <Icon name="click" color="#fff"/>
                <CustomFont display="inline" color="#fff">
                  爱你♥
                </CustomFont>
              </Btn>
            </div>
            <div class="index__about__button__item" :style="heartStyle">
              <canvas id="cvs" style="width: 100px;height: 235px;"></canvas>
            </div>
          </div>
          <div style="height: 100px;width: 210px;margin: 0 auto">
            <transition enter-active-class="animated fadeInLeft">
              <div style="display: inline;float: left" v-show="visible">
<!--                <img class="avatar" src="../static/heart/img/wife.jpg">-->
                <img class="avatar" src="../static/heart/img/xiaoyaya.jpg">
              </div>
            </transition>
            <div style="display: inline;float: right">
<!--              <img class="avatar" src="../static/heart/img/avatar.jpg">-->
              <img class="avatar" src="../static/heart/img/xiaoniuniu.jpg">
            </div>
          </div>
<!--          <div v-show="visible" style="margin: 10px">-->
<!--            <span class="text-white" style="font-size: 10px;font-weight: 300;">{{ loveDays }} days {{ loveHours }} hours {{ loveMinutes }} minutes {{ loveSeconds }} seconds</span>-->
<!--          </div>-->
        </div>
        <div v-show="visible" style="margin: 10px">
          <span class="text-white" style="font-size: 10px;font-weight: 300;">{{ loveDays }} days {{ loveHours }} hours {{ loveMinutes }} minutes {{ loveSeconds }} seconds</span>
        </div>
      </SliderItem>
    </Slider>
  </div>
</template>

<script>
import StarSky from "@/components/StarSky";
import Slider from "@/components/Slider";
import SliderItem from "@/components/SliderItem";
import CustomFont from "@/components/CustomFont";
import Blocker from "@/components/Blocker";
import Btn from "@/components/Btn";
import Icon from "@/components/Icon";

import LikeHeart from "@/static/heart/js/heart";
import confetti from 'canvas-confetti/src/confetti'

export default {
  name : 'StarIndex',
  components:{
    StarSky,
    Slider,
    SliderItem,
    CustomFont,
    Blocker,
    Btn,
    Icon
  },
  data(){
    return {
      width: 175, //初始宽度
      height: 400, //初始高度
      heartList: [], //初始数组
      heartCount: 0, //累加计数初始值
      visible:false,
      heartStyle:{maxWidth: '200px'},
      timer:null,
      loveDays:null,
      loveHours:null,
      loveMinutes:null,
      loveSeconds:null
    };
  },
  methods:{
    showLove(){
      this.visible=true
      this.heartStyle['margin']='0 auto'
      confetti({
        particleCount: 100,
        spread: 60
      });
    },
    count() {
      let loveTime=new Date()-new Date('2021-06-21 21:52:00').getTime();
      this.loveDays=Math.floor(loveTime/(24*3600*1000))
      let temp1=loveTime%(24*3600*1000);
      this.loveHours=Math.floor(temp1/(3600*1000));
      let temp2=temp1%(3600*1000);
      this.loveMinutes=Math.floor(temp2/(60*1000));
      let temp3=temp2%(60*1000);
      this.loveSeconds=Math.floor(temp3/1000);
    },
    getRandomDis() {
      if (Math.random() > 0.5) {
        return -(Math.random() * 43);
      } else {
        return +(Math.random() * 43);
      }
    },
    createHeart() {
      this.heartCount++;
      let positionArray = [
        {
          x: 100,
          y: 400,
          endX: 100,
          endY: 100
        }
      ];
      let img = new Image();
      // img.src = "../../static/img/" + Math.ceil(Math.random() * 2) + ".png";
      img.src = require(`../static/heart/img/${Math.ceil(Math.random() * 3)}.png`);
      let p1 = {
        x: 100 + this.getRandomDis(),
        y: 300 + this.getRandomDis()
      };
      let p2 = {
        x: 100 + this.getRandomDis(),
        y: 200 + this.getRandomDis()
      };
      return new LikeHeart({
        id: this.heartCount,
        x: positionArray[0].x,
        y: positionArray[0].y,
        endX: positionArray[0].endX,
        endY: positionArray[0].endY,
        onFadeOut: this.removeItem,
        noAngel: true,//决定是否从小到大
        // noScale: true,//决定是否左右摆动
        width: 30, //决定心的大小
        height: 30,
        image: img,
        bezierPoint: {
          p0: {
            x: positionArray[0].x,
            y: positionArray[0].y
          },
          p1: p1,
          p2: p2,
          p3: {
            x: positionArray[0].endX,
            y: positionArray[0].endY
          }
        }
      });
    },
    removeItem(item) {
      var array = [];
      for (var i = 0; i < this.heartList.length; i++) {
        if (this.heartList[i].id !== item.id) {
          array.push(this.heartList[i]);
        }
      }
      this.heartList = array;
    }
  },
  mounted() {
    // 飘心
    var _this = this;
    var ctx = document.getElementById("cvs").getContext("2d");
    (ctx.canvas.width = _this.width),
        (ctx.canvas.height = _this.height),
        (function loop() {
          ctx.clearRect(0, 0, _this.width, _this.height);
          _this.heartList.forEach(function(item) {
            item && item.move(ctx);
          });
          requestAnimationFrame(loop);
        })();
    setInterval(function() {
      _this.heartList.push(_this.createHeart());
    }, 700);
    document.getElementById("cvs").addEventListener(
        "click",
        function() {
          console.log(111111)
          _this.heartList.push(_this.createHeart());
        },
        false
    );
  },
  created() {
    if (this.timer) {
      clearInterval(this.timer);
    } else {
      this.timer = setInterval(this.count, 1000);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
}
</script>
